import { CSSProperties, PropsWithChildren } from "react"
import { use100vh } from "react-div-100vh"
import { Container, InnerContainer } from "./body_style"
import Footer from "./footer"
import Header from "./header"

interface BodyProps {
	className?: string
	noPadding?: boolean
	whiteFooter?: boolean
	subtitle?: string
	subtitleLink?: string
	style?: CSSProperties
}

const Body = ({
	children,
	subtitle,
	className = undefined,
	noPadding = false,
	whiteFooter = false,
	subtitleLink,
	style,
}: PropsWithChildren<BodyProps>) => {
	const height = use100vh()

	return (
		<Container
			height={height}
			className={`bg-white dark:bg-black text-black dark:text-white ${className}`}
			style={style}
		>
			<Header subtitle={subtitle} subtitleLink={subtitleLink} />
			{noPadding ? children : <InnerContainer>{children}</InnerContainer>}

			<Footer
				className={whiteFooter ? "text-white" : undefined}
				separatorClassName={whiteFooter ? "bg-white" : "bg-black"}
			/>
		</Container>
	)
}

export default Body
