import Router from "next/router"
import React from "react"
import {
	AuthData,
	authFromContext,
	authFromStore,
} from "../../stores/authStore"

export const loginRedirect = (from: string): string => {
	let login = "/login"
	if (from) {
		login += `?redirect=${from}`
	}
	return login
}

export const withPrivateRoute = (WrappedComponent) => {
	const hocComponent = ({ ...props }) => <WrappedComponent {...props} />

	hocComponent.getInitialProps = async (context) => {
		let auth: AuthData
		if (context.req && context.req.headers && context.req.headers.cookie) {
			auth = authFromContext(context)
		} else {
			auth = authFromStore()
		}

		if (
			!auth ||
			!auth.token ||
			!auth.refreshExpiresIn ||
			new Date().getTime() / 1000 > auth.refreshExpiresIn
		) {
			if (context.res) {
				context.res?.writeHead(302, {
					Location: loginRedirect(context.asPath),
				})
				context.res?.end()
			} else {
				Router.replace(loginRedirect(context.asPath))
			}
		} else if (WrappedComponent.getInitialProps) {
			const wrappedProps = await WrappedComponent.getInitialProps({
				...context,
				auth,
			})
			return { ...wrappedProps, auth }
		}

		return { auth }
	}

	return hocComponent
}
export default withPrivateRoute
