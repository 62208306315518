import Router from "next/router"
import { loginRedirect } from "../components/privateRoute/withPrivateRoute"
import { AuthData, authFromStore, useAuthStore } from "../stores/authStore"
import { useErrorStore } from "../stores/errorStore"
import formatUrl from "../utils/formatUrl"

// General

const formatConfig = (
	body?: BodyInit | Record<string, unknown>,
	method?: string,
	token?: string
) => {
	const config: RequestInit = {
		method,
	}
	const headers = {}

	if (token) {
		headers["Authorization"] = `Bearer ${token}`
	}

	if (body) {
		if (body instanceof FormData) {
			config.body = body as BodyInit
		} else {
			config.body = JSON.stringify(body)
		}
	}

	if ((config.body && typeof config.body === "string") || !config.body) {
		headers["Content-Type"] = "application/json"
	}

	config.headers = headers

	return config
}

// Normal fetching

export const fetchNormal = async (
	url: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	try {
		const result = await fetch(url, formatConfig(body, method))
		const json = await result.json()
		if (json.message) {
			throw new Error(json.message)
		} else {
			return json
		}
	} catch (error) {
		useErrorStore.getState().update(error)
	}
}

export const fetchApi = async (
	url: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	return fetchNormal(formatUrl(url), body, method)
}

// Token refresh

const _refresh = async (refreshToken: string) => {
	const data = await fetchApi("/auth/refresh", { refreshToken }, "POST")
	if (data as AuthData) {
		useAuthStore.getState().update(data)
	} else {
		throw new Error((data && data.message) ?? "Unknown error")
	}
}

// Authorized fetching

const fetchAuthorized = async (
	url: string,
	token: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	try {
		const result = await fetch(url, formatConfig(body, method, token))
		const json = await result.json()
		if (json.message) {
			throw new Error(json.message)
		} else {
			return json
		}
	} catch (error) {
		useErrorStore.getState().update(error)
		if (error.message === "Invalid Token") {
			console.log("Invalid Token")
			document.cookie = ""

			Router.replace(loginRedirect(Router.asPath))
		}
	}
}

const _fetchAuthorized = async (
	url: string,
	token: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	return fetchAuthorized(formatUrl(url), token, body, method)
}

export const fetchApiAuthorized = async (
	url: string,
	auth: AuthData,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	if (
		auth === undefined &&
		Array.isArray(url) &&
		(url as string[]).length > 1
	) {
		auth = (url as string[])[1] as unknown as AuthData
		url = (url as string[])[0]
	}

	const { token, expiresIn, refreshToken, refreshExpiresIn } = auth
	const now = new Date().getTime() / 1000 + 60

	if (!token || !expiresIn || !refreshToken || !refreshExpiresIn) {
		Router.replace("/login")
		return
	}

	if (expiresIn > now) {
		return _fetchAuthorized(url, token, body, method)
	} else {
		if (now > refreshExpiresIn) {
			Router.replace("/login")
		} else {
			await _refresh(refreshToken)

			const { token } = authFromStore()

			if (!token) {
				Router.replace("/login")
				return
			}
			return _fetchAuthorized(url, token, body, method)
		}
	}
}
