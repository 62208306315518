import { BASE_URL } from "./baseUrl"

const formatUrl = (
	url: string,
	parameter?: Record<string, string | number | boolean>,
	withBaseUrl = true
) => {
	if (!parameter || parameter === null) {
		return BASE_URL + url
	}

	Object.keys(parameter).forEach((key) =>
		parameter[key] === undefined ? delete parameter[key] : {}
	)

	const esc = encodeURIComponent
	const query = Object.keys(parameter)
		.map((k) => {
			return esc(k) + "=" + esc(parameter[k])
		})
		.join("&")

	let final = url
	if (query.length > 0) {
		final += "?" + query
	}

	if (withBaseUrl) {
		final = BASE_URL + final
	}

	return final
}

export default formatUrl
