import { HorizontalGroup, Input } from "@saysom/shared"
import { Buffer } from "buffer"
import { useRouter } from "next/router"
import { useEffect, useMemo, useState } from "react"
import Anchor from "../components/anchor/anchor"
import Body from "../components/body/body"
import { fetchApi } from "../network/fetch"
import { useAuthStore } from "../stores/authStore"
import {
	BlackButton,
	ButtonIcon,
	CenterContainer,
	Form,
	Label,
} from "../styles"
import { BASE_URL } from "../utils/baseUrl"

const Login = () => {
	const router = useRouter()

	const redirect =
		router.query.redirect && (router.query.redirect as string)
			? (router.query.redirect as string)
			: "/"

	const academicUrl = useMemo(() => {
		const url = new URL(BASE_URL)
		url.pathname = "/auth/oauth"

		const redirectUrl = new URL(
			typeof window !== "undefined"
				? window.location.href
				: "https://saysom.app"
		)
		redirectUrl.pathname = "/login"
		redirectUrl.search = ""
		redirectUrl.searchParams.append("redirect", encodeURI(redirect))

		url.searchParams.append(
			"redirect",
			Buffer.from(JSON.stringify(redirectUrl.toString())).toString("base64")
		)

		return url.toString()
	}, [redirect])

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")

	const _login = async (e) => {
		if (e) {
			e.preventDefault()
		}

		const result = await fetchApi("/users/login", { email, password }, "POST")
		if (result) {
			useAuthStore.getState().update(result)

			router.push(redirect)
		}
	}

	useEffect(() => {
		if (
			router.query.token &&
			router.query.token !== "" &&
			router.query.redirect &&
			router.query.redirect !== ""
		) {
			const rawToken = JSON.parse(
				Buffer.from(decodeURI(router.query.token as string), "base64").toString(
					"utf8"
				)
			)
			useAuthStore.getState().update(rawToken)

			const redirectUrl = decodeURI(router.query.redirect as string)

			router.push(redirectUrl)
		}
	}, [router.query, router])

	// TODO: if url params has redirect, redirect to that page after login

	return (
		<Body subtitle="Login">
			<CenterContainer
				width="500px"
				style={{ flex: 1 }}
				className="divide-y space-y-6"
			>
				<Form style={{ alignSelf: "stretch" }} onSubmit={_login}>
					<Label>
						Email
						<Input
							placeholder="Email"
							type="email"
							value={email}
							onChange={(value) => setEmail(value)}
						/>
					</Label>
					<Label style={{ marginBottom: "5px" }}>
						Password
						<Input
							placeholder="Password"
							type="password"
							value={password}
							onChange={(value) => setPassword(value)}
						/>
					</Label>
					<HorizontalGroup justifyContent="space-between">
						<p className="text-sm">
							No account yet? <Anchor href="/register">Register</Anchor>
						</p>
						<Anchor href="/forgot/password" className="text-sm">
							Forgot Password?
						</Anchor>
					</HorizontalGroup>
					<BlackButton
						style={{ alignSelf: "center", marginTop: "50px" }}
						type="submit"
						disabled={email.length === 0 || password.length === 0}
					>
						<ButtonIcon
							src="/assets/images/arrowRight.svg"
							color="white"
							width="21px"
							height="14px"
						/>
					</BlackButton>
				</Form>

				<div className="flex self-stretch justify-center pt-6">
					<a
						href={academicUrl}
						className="flex flex-row py-3 px-6 items-center border border-black border-opacity-50 rounded-full space-x-2 shadow-sm text-sm hover:shadow-md transition-all"
					>
						<span>Login with</span>
						<img
							width="80px"
							height="auto"
							src="/academiccloud.svg"
							alt="AcademicCloud"
						/>
					</a>
				</div>
			</CenterContainer>
		</Body>
	)
}

export default Login
