// TODO: fix
// local: `http://${window.location.host.split(":")[0]}:3004`,

const locations = {
	live: "https://api.saysom.app",
	staging: "https://api.staging.saysom.app",
	local: "http://localhost:3000",
}

const appLocations = {
	live: "https://web.saysom.app",
	staging: "https://web.staging.saysom.app",
	local: "http://localhost:3002",
}

// const blogLocations = {
// 	live: "https://blog.saysom.app/graphql",
// 	staging: "https://blog.saysom.app/graphql",
// 	local: "https://blog.saysom.app/graphql",
// 	// local: "http://localhost:8888/wordpress/graphql",
// }

let location = "local"
if (process.env.NODE_ENV === "production") {
	if (process.env.NEXT_PUBLIC_STAGING === "1") {
		location = "staging"
	} else {
		location = "live"
	}
}

export const BASE_URL = locations[location]
export const APP_URL = appLocations[location]
// export const BLOG_URL = blogLocations[location]
